import {
  HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Observable,
} from 'rxjs';
import {
  catchError,
} from 'rxjs/operators';

import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

import { environment } from "../../../environments/environment";
import { AbstractUserProvider } from "@skykick/platform-identity-auth-auth0-angular";
import { Utils } from "../../services/utils";

@Injectable({
    providedIn: 'root'
})
export class BillingService {

  constructor(
    private http: HttpClient,
    private abstractUserProvider: AbstractUserProvider,
    private routeResolverService: RouteResolverService
  ) {
  }

  getBillingPortalUrl(): string {
    return this.routeResolverService.generateRatRoute(AppId.BillingPortal);
  }

  isAnyPastDueInvoices(): Observable<boolean> {
    return this.http.get<boolean>(`${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}${environment.apis.accountingApimSuffix}/api/v1/invoice/pastdue/${this.abstractUserProvider.getCurrentUser().partnerId}/isany`)
      .pipe(catchError(error => Utils.handleError(error, BillingService.name)));
  }
}

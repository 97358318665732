<div class="sk-page-header header-light py-100">
    <!-- NAVBAR PRIMARY -->
    <div class="sk-navbar-primary mb-0">
        <!-- JUSTIFY LEFT -->
        <div class="d-flex align-items-center min-w-0">
            <!-- min-width for flex content -->
            <div class="d-flex min-w-0">
                <div class="sk-navbar-heading min-w-0">
                    <h1 class="text-truncate">{{'home.hi_username' | translate: usernameTranslationParam}}</h1>
                </div>
            </div>
        </div>
        <!-- JUSTIFY RIGHT -->
        <div class="d-flex flex-shrink-0 min-w-0">
            <!-- Show IF Welcome CTAs have been dismissed -->
            <button *ngIf="!showWelcome" (click)="onShowWelcomeChanged(true)" class="btn btn-link mr-100">Get
                Started</button>
        </div>
    </div>

    <div class="sk-flex-table" *ngIf="isAnyPastDueInvoices">
        <sk-feedback [dismissible]="false" icon="warning" class="bg-yellow-200 min-width-xl max-width-xxl mt-3" style="max-width: 1688px;"
                     [subject]="'home.unpaid_invoices_feedback.subject' | translate" type="warning">
            <span [innerHtml]="'home.unpaid_invoices_feedback.text'
               | translate: { billingPortalUrl: getBillingPortalUrl(), billingAndInvoicingSupportPage: getSkyKickBillingAndInvoicingSupportPageUrl() }"></span>
        </sk-feedback>
    </div>

    <!-- NAVBAR SECONDARY -->
    <div class="sk-navbar-secondary d-none">
        <!-- Navbar Option -->
        <ul class="nav sk-tabs-lg d-flex flex-nowrap text-truncate">
            <li class="nav-item ">
                <a href="#" class="nav-link active" data-bs-original-title="" title="">First Ipsum</a>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link " data-bs-original-title="" title="">Second Dolor</a>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link" data-bs-original-title="" title="">Third Sit Amet</a>
            </li>
        </ul>
        <div class="d-flex">
            <button type="button" id="iconBtnAction" class="btn sk-btn-icon sk-panel-btn mr-25"><i
                    class="material-icons md-22 ">flash_on</i> </button>

            <button type="button" id="iconBtnInfo" class="btn sk-btn-icon sk-panel-btn"><i
                    class="material-icons md-22">info_outline</i> </button>
        </div>
    </div>
</div>
<div class="sk-flex-table">
    <div class="sk-scroll-container px-0">
        <sk-welcome [showWelcome]="showWelcome" (showWelcomeChange)="onShowWelcomeChanged($event)"></sk-welcome>
        <div class="row g-0 min-width-xl max-width-xxl px-150 mb-100 pt-100">
            <div class="col-sm-4 col-lg-4 col-xl-4 col-xxl-4 pb-100 px-50">
                <sk-backup-card></sk-backup-card>
            </div>
            <div class="col-sm-4 col-lg-4 col-xl-4 col-xxl-4 pb-100 px-50">
                <sk-cloud-manager-card></sk-cloud-manager-card>
            </div>
            <div class="col-sm-4 col-lg-4 col-xl-4 col-xxl-4 pb-100 px-50">
                <sk-migrations-card></sk-migrations-card>
            </div>
        </div>
        <div class="row g-0 min-width-xl max-width-xxl px-150 mb-100">
            <div class="col-sm-6 px-50">
                <div class="card ">
                    <div class="card-header sk-card-header">
                        <h4>Offers</h4>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <!--HubSpot Call-to-Action Code -->
                            <span class="hs-cta-wrapper" id="hs-cta-wrapper-7b217356-37c9-4b5f-b1ac-5d0fc4c001d5"><span
                                    class="hs-cta-node hs-cta-7b217356-37c9-4b5f-b1ac-5d0fc4c001d5"
                                    id="hs-cta-7b217356-37c9-4b5f-b1ac-5d0fc4c001d5">
                                    <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
                                    <a href="https://cta-redirect.hubspot.com/cta/redirect/2047087/7b217356-37c9-4b5f-b1ac-5d0fc4c001d5"
                                        target="_blank" rel="noopener">
                                        <img class="hs-cta-img" id="hs-cta-img-7b217356-37c9-4b5f-b1ac-5d0fc4c001d5"
                                            style="border-width:0px;"
                                            src="https://no-cache.hubspot.com/cta/default/2047087/7b217356-37c9-4b5f-b1ac-5d0fc4c001d5.png"
                                            alt="See New Platform Pricing The plans bring together Cloud Manager, Backup and Migration to help you  automate cloud ops for an unbeatable value.See Pricing" />
                                    </a>
                                </span>

                            </span>
                            <!-- end HubSpot Call-to-Action Code -->
                        </li>
                        <li class="list-group-item">
                            <!--HubSpot Call-to-Action Code -->
                            <span class="hs-cta-wrapper" id="hs-cta-wrapper-3b84e8ca-5a7c-45e0-8af6-8cd719db173c">
                                <span class="hs-cta-node hs-cta-3b84e8ca-5a7c-45e0-8af6-8cd719db173c"
                                    id="hs-cta-3b84e8ca-5a7c-45e0-8af6-8cd719db173c">
                                    <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
                                    <a href="https://cta-redirect.hubspot.com/cta/redirect/2047087/3b84e8ca-5a7c-45e0-8af6-8cd719db173c"
                                        target="_blank" rel="noopener">
                                        <img class="hs-cta-img" id="hs-cta-img-3b84e8ca-5a7c-45e0-8af6-8cd719db173c"
                                            style="border-width:0px;"
                                            src="https://no-cache.hubspot.com/cta/default/2047087/3b84e8ca-5a7c-45e0-8af6-8cd719db173c.png"
                                            alt="Start Cloud Manager for 30 Days Free Get hands on with this exciting new product - a single pane of glass to manage  and secure your customers in the cloud.Start Now" />
                                    </a>
                                </span>
                            </span>
                            <!-- end HubSpot Call-to-Action Code -->
                        </li>
                        <li class="list-group-item">
                            <!--HubSpot Call-to-Action Code -->
                            <span class="hs-cta-wrapper" id="hs-cta-wrapper-7c980824-0683-4682-bced-862cd0ede010">
                                <span class="hs-cta-node hs-cta-7c980824-0683-4682-bced-862cd0ede010"
                                    id="hs-cta-7c980824-0683-4682-bced-862cd0ede010">
                                    <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
                                    <a href="https://cta-redirect.hubspot.com/cta/redirect/2047087/7c980824-0683-4682-bced-862cd0ede010"
                                        target="_blank" rel="noopener">
                                        <img class="hs-cta-img" id="hs-cta-img-7c980824-0683-4682-bced-862cd0ede010"
                                            style="border-width:0px;"
                                            src="https://no-cache.hubspot.com/cta/default/2047087/7c980824-0683-4682-bced-862cd0ede010.png"
                                            alt=" " />
                                    </a>
                                </span>
                            </span>
                            <!-- end HubSpot Call-to-Action Code -->
                        </li>
                    </ul>
                </div>
            </div>
            <!-- /.col -->
            <div class="col-sm-6 px-50">
                <div class="card mb-100">
                    <div class="card-block">
                        <!--HubSpot Call-to-Action Code -->
                        <span class="hs-cta-wrapper" id="hs-cta-wrapper-8d6d16a0-a2cb-47fe-a2b3-3d667b9d021f">
                            <span class="hs-cta-node hs-cta-8d6d16a0-a2cb-47fe-a2b3-3d667b9d021f"
                                id="hs-cta-8d6d16a0-a2cb-47fe-a2b3-3d667b9d021f">
                                <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
                                <a href="https://cta-redirect.hubspot.com/cta/redirect/2047087/8d6d16a0-a2cb-47fe-a2b3-3d667b9d021f"
                                    target="_blank" rel="noopener">
                                    <img class="hs-cta-img" id="hs-cta-img-8d6d16a0-a2cb-47fe-a2b3-3d667b9d021f"
                                        style="border-width:0px;"
                                        src="https://no-cache.hubspot.com/cta/default/2047087/8d6d16a0-a2cb-47fe-a2b3-3d667b9d021f.png"
                                        alt="Explore the Readiness Hub See cloud strategy guides for business growth and get customizable content to  help sell your services. Learn more" />
                                </a>
                            </span>
                        </span>
                        <!-- end HubSpot Call-to-Action Code -->
                    </div>
                </div>
                <div class="card">
                    <div class="card-block">
                        <!--HubSpot Call-to-Action Code -->
                        <span class="hs-cta-wrapper" id="hs-cta-wrapper-a81c29f2-ba4d-4b9b-9d38-032c9b412d10">
                            <span class="hs-cta-node hs-cta-a81c29f2-ba4d-4b9b-9d38-032c9b412d10"
                                id="hs-cta-a81c29f2-ba4d-4b9b-9d38-032c9b412d10">
                                <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
                                <a href="https://cta-redirect.hubspot.com/cta/redirect/2047087/a81c29f2-ba4d-4b9b-9d38-032c9b412d10"
                                    target="_blank" rel="noopener">
                                    <img class="hs-cta-img" id="hs-cta-img-a81c29f2-ba4d-4b9b-9d38-032c9b412d10"
                                        style="border-width:0px;"
                                        src="https://no-cache.hubspot.com/cta/default/2047087/a81c29f2-ba4d-4b9b-9d38-032c9b412d10.png"
                                        alt="Discover ways to test drive Cloud Manager Cloud Manager empowers the Help Desk through automation and improves the  overall efficiency, security, and quality of your support operations Learn more" />
                                </a>
                            </span>
                        </span>
                        <!-- end HubSpot Call-to-Action Code -->
                    </div>
                </div>

            </div>
            <!-- /.col -->

        </div>
    </div>
</div>

import {
  Component, OnDestroy,
  OnInit,
} from '@angular/core';

import {
  CacheExpiry,
  CacheNamespace,
  CacheService,
} from '@skykick/caching';
import {
  AbstractUserProvider,
} from '@skykick/platform-identity-auth-auth0-angular';
import { MpnCheckService } from '../mpn-registration/services/mpn-check.service';
import { BillingService } from "./services/billing.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { PublicUrlsProvider } from "../settings/shared/services/public.urls.provider";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  usernameTranslationParam = { "username": "" };
  showWelcome: boolean = true;
  isAnyPastDueInvoices: boolean = false;
  cache: CacheNamespace;

  constructor(
    cacheService: CacheService,
    private billingService: BillingService,
    private userProvider: AbstractUserProvider,
    private mpnCheckService: MpnCheckService
  ) {
    this.cache = cacheService.root().withNamespace('home');
  }

  ngOnInit(): void {
    // Check if we need to get the MPN from the user after login, kick them over to the account setup page.
    this.mpnCheckService.homepageCheck();

    this.showWelcome = this.cache.get<boolean>('showWelcomeComponent') ?? true;
    this.usernameTranslationParam.username = this.userProvider.getCurrentUser().firstName;

    this.billingService.isAnyPastDueInvoices()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => this.isAnyPastDueInvoices = value);
  }

  onShowWelcomeChanged(newValue: boolean): void {
    this.showWelcome = newValue;
    this.cache.set('showWelcomeComponent', this.showWelcome, CacheExpiry.Never);
  }

  getBillingPortalUrl(): string {
    return this.billingService.getBillingPortalUrl();
  }

  getSkyKickBillingAndInvoicingSupportPageUrl(): string {
    return PublicUrlsProvider.BillingAndInvoicingSupportPageUrl;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
